<template>
<div class="mx-auto p-2" style="width: 800px;">
  <div class="text-center">
    <img src="../assets/logo_60x60.png" alt="UJJK logo"/>
  </div>
  <div class="text-center">
    <h1>Anmälan till Uppsala ju-jutsuklubb</h1>
  </div>
  <div>
    <!--
    <b-alert variant="warning" show>Anmälan för nybörjare till höstterminen 2022 är stängd. Anmälan till vårterminen 2023 kommer öppna den 18 december.</b-alert -->
    <p>Har du inte tränat stilen Ju-jutsu Kai tidigare, så är du välkommen att fylla i "Anmälan för nybörjare" nedan. Efter anmälan får du komma och provträna hos oss, innan du väljer att betala.</p>
    <p>Du som vill börja träna hos oss och redan är graderad i stilen <a href="https://www.ju-jutsukai.se/" target="_blank" rel="noopener noreferrer">Svenska ju-jutsu Kai</a> kan anmäla dig som "Befintlig medlem" här nedan, och ansluta till nästa träningspass enligt <a href="http://ujjk.se/schema">schemat</a>. På vår hemsida finns också information om <a href="http://ujjk.se/avgifter">betalning och avgifter</a>.</p>

    <p>Har du frågor? Kontakta <a href="mailto:info@ujjk.se">info@ujjk.se</a>.</p>
  </div>
  <b-card bg-variant="light">
    <RegisterProspectForm/>
  </b-card>
</div>
</template>

<script>
  // @ is an alias to /src
  import RegisterProspectForm from '@/components/RegisterProspectForm.vue'

  export default {
    name: "RegisterProspect",
    components: { RegisterProspectForm }
  }
</script>
